import React from 'react'
import Accordion from 'Components/UI/Accordion'
import BackgroundBtn from 'Components/UI/BackgroundBtn'
import AppConstants from 'AppConstants'
import Form from 'Components/UI/Form'
import Input from 'Components/UI/Input'
import styles from './index.module.scss'

const PromoCodeAccordion = React.forwardRef((props, ref) => (
  <div className={`${styles.parent}`}>
    <Accordion panelClassName={`t-color-black`} textClassName={`t-title-1 u-uppercase`} title={`Do you have a promo code?`} open={false}>
      <div className={'t-paragraph-0 u-text-center u-opacity--50'}>Promo codes not valid for sale items</div>
      <Form items={[]} submit={props.onFormSubmit}>
        <div className={`${styles.giftCardContainer} u-row`}>
          <Input
            className={`u-offset-1 u-col-10`}
            required={true}
            placeholder={'Enter promo code'}
            value={``}
            fullBorder
            ref={ref}
            type={`text`}
          />
          <div className={`u-height--lg--10`} />
          <div className={`u-height--only-lg--10`} />
          { !props.isLoading &&
          <BackgroundBtn
            size={'md'}
            type={`submit`}
            className={`u-offset-1 u-col-10`}
            fullBlack
            text={`Apply code`}
          />
          }
          { props.isLoading &&
          <div className={styles.spinnerWrapper}>
            <img className={`${styles.spinner}`} src={`/images/spinner_white.gif`} />
          </div>
          }
        </div>
      </Form>
      <div className={`u-height--lg--30`} />
      <div className={`u-height--only-lg--20`} />
      <div className='u-separator t-bg-gray'></div>
      <div className={`u-height--lg--20`} />
      <div className={`u-height--only-lg--20`} />
    </Accordion>
  </div>
))

export default PromoCodeAccordion
