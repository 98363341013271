import React, { PureComponent } from 'react'
import AppActions from 'AppActions'
import AppStore from 'AppStore'
import AppConstants from 'AppConstants'
import Layout from 'Components/Layout'
import SeoHead from 'Components/SeoHead'
import Breadcrumbs from 'Components/UI/Breadcrumbs'
import Img from 'Components/UI/Img'
import RemoveButton from 'Components/UI/RemoveButton'
import MainBtn from 'Components/UI/MainBtn'
import Select from 'Components/UI/Select'
import styles from '../styles/pages/bag.module.scss'
import BackgroundBtn from 'Components/UI/BackgroundBtn'
import SummaryInformation from 'Components/UI/SummaryInformation'
import PaypalButton from 'Components/UI/PaypalButton'
import Price from 'Components/Utils/FormatPrice'
import PromoCodeAccordion from 'Components/UI/PromoCodeAccordion'
import { WARNING } from 'Components/UI/Notifications'
import { cancelablePromise, getBagItems } from 'Components/Utils/helpers'
import { graphql, navigate } from 'gatsby'
require('@gouch/to-title-case')

class BagPage extends PureComponent {
  constructor(props) {
    super(props)
    this.promoForm = React.createRef()
    this.breadcrumbsData = [
      {
        path: `${this.props.location.pathname}`,
        title: 'Bag'
      }
    ]
    this.state = {
      promoIsLoading: false,
      promo: undefined,
      promoIsApplied: false,
      items: getBagItems(),
      isCustomer: AppStore.userIsValid(AppStore.getCustomer()),
      sizes: []
    }
  }
  componentDidMount() {
    AppStore.on(AppConstants.ADD_TO_BAG, this.updateCurrentBagProducts)
    AppStore.on(AppConstants.REMOVE_FROM_BAG, this.updateCurrentBagProducts)
    AppStore.on(AppConstants.MOVE_FAVORITES_TO_BAG, this.updateCurrentBagProducts)
    AppStore.on(AppConstants.UPDATE_BAG_PRODUCT_SIZE, this.updateCurrentBagProducts)
    AppStore.on(AppConstants.APPLY_PROMO_CODE, this.didPromoCodeApply)
    AppStore.on(AppConstants.CUSTOMER_STATUS_CHANGED, this.checkCustomer)
    if (AppStore.promoCodes && AppStore.promoCodes.length > 0) {
      this.setState({ promoIsApplied: true, promoIsLoading: false, promo: AppStore.promoCodes[0] })
    }
    this.fetchSizes()
  }
  componentWillUnmount() {
    if (this.productSizesFetch) this.productSizesFetch.cancel()
    AppStore.off(AppConstants.ADD_TO_BAG, this.updateCurrentBagProducts)
    AppStore.off(AppConstants.REMOVE_FROM_BAG, this.updateCurrentBagProducts)
    AppStore.off(AppConstants.MOVE_FAVORITES_TO_BAG, this.updateCurrentBagProducts)
    AppStore.off(AppConstants.UPDATE_BAG_PRODUCT_SIZE, this.updateCurrentBagProducts)
    AppStore.off(AppConstants.APPLY_PROMO_CODE, this.didPromoCodeApply)
    AppStore.off(AppConstants.CUSTOMER_STATUS_CHANGED, this.checkCustomer)
  }
  updateCurrentBagProducts = () => {
    this.setState({ items: getBagItems() }, this.fetchSizes)
  }
  fetchSizes = () => {
    if (this.productSizesFetch) this.productSizesFetch.cancel()
    const ids = this.state.items.map(item => item.product.id)
    this.productSizesFetch = cancelablePromise(fetch(`${AppConstants.API_URL}available-product-sizes?id=${ids.join(',')}`))
    this.productSizesFetch.promise
    .then(res => res.json())
    .then(sizes => {
      setTimeout(AppActions.finishLoadingPage, AppConstants.LOADING_PAGE_TIMEOUT)
      this.setState({ sizes })
    })
    .catch(e => { setTimeout(AppActions.finishLoadingPage, AppConstants.LOADING_PAGE_TIMEOUT) })
  }
  onRemoveButtonClick = (product) => {
    setTimeout(AppActions.removeFromBag, 0, product)
  }
  onProductSizeChange = (data, newSize) => {
    const item = {
      product: data.item.product,
      uuid: data.item.uuid,
      size: data.productSizes.sizes.filter(size => size.id === newSize.value.value)[0]
    }
    setTimeout(AppActions.updateBagProductSize, 0, item)
  }
  onCheckoutClick = () => {
    for (let index = 0; index < this.state.items.length; index++) {
      const element = this.state.items[index]
      const { size } = element
      if (!size) {
        setTimeout(AppActions.openNotification, 0, { type: WARNING, message: `Please select a size, one or more missing...` })
        return
      }
    }
    navigate(`/checkout`)
  }
  didPromoCodeApply = (data) => {
    this.promoForm.current.setValueExplicit('')
    if (data.validation && data.validation.is_valid) this.setState({ promoIsApplied: true, promoIsLoading: false, promo: data })
    else this.setState({ promoIsLoading: false })
  }
  onFormSubmit = () => {
    this.setState({ promoIsLoading: true })
    const code = this.promoForm.current.state.value
    const totalAmount = AppStore.bagItemsTotalAmount()
    const customer = AppStore.getCurrentCustomer()
    const bag = AppStore.getBag()
    setTimeout(AppActions.applyPromoCode, 0, { total_amount: totalAmount.totalWithSale, code, email: customer.email, items: bag })
  }
  checkCustomer = () => {
    this.setState({ isCustomer: AppStore.userIsValid(AppStore.getCustomer()) })
  }
  render() {
    const isCustomer = this.state.isCustomer
    const isPrivateSale = this.props.data.dataJson.PRIVATE_SALE
    const lockSale = !isCustomer && isPrivateSale
    const products = this.state.items.map((item, index) => {
      const { product, size } = item
      const showSale = product && product.sale.inSale && !lockSale
      const productSizes = this.state.sizes.filter(s => s.id === product.id)[0]
      const sizeOptions =
        (productSizes && productSizes.sizes)
        ? productSizes.sizes.filter(s => s.in_stock).map(s => {
          return { value: s.id, label: s.name }
        })
        : []
      let currentSize
      if (size) {
        currentSize = sizeOptions.filter(option => { return option.value === size.id })[0]
        if (!currentSize) currentSize = ''
      } else {
        currentSize = ''
      }
      const src = product.media.rect[0].srcSmall
      const srcRetina = product.media.rect[0].src
      const price = Price.format( AppConstants.LOCALE, AppConstants.CURRENCY, product.price )
      return (
        <div className={`${styles.productBlock} u-col-12`} key={`product-bag-${currentSize && currentSize.value ? currentSize.value : ''}-${product.id}-${index}`}>
          <div className={`${styles.listContainer} u-flex u-align-items-c`}>
            <div className={`u-btn u-col-4 u-col-lg-2`} onClick={ () => navigate(`/${product.permalink}`) }>
              <Img
                baseUrl={AppConstants.CLOUDFRONT_CONTENT_URL}
                className={`${styles.productImg} u-w-perc--100 u-h-perc--100`}
                src={src}
                srcRetina={srcRetina}
              />
            </div>
            <div className={`${styles.infoPanel} u-mrg-l--40 u-col-3 u-col-lg-4 u-hide-in-mobile`}>
              <div className={`t-title-1 u-uppercase`}>{product.title}</div>
              <div className='u-height--10' />
              <div className={`t-paragraph-0 t-color-gray u-hide-in-mobile`}>StyleID: {product.style_id} – SKU: {product.sku}</div>
              <div className={`t-paragraph-0 t-color-gray u-hide-in-mobile`}>Color: {product.color.name.toTitleCase()}</div>
              <div className={`t-paragraph-0 t-color-gray u-hide-in-mobile`}>
              { !showSale && <span>{price}</span> }
              { showSale &&
              <span>
                <del>
                  <span>{price}</span>
                </del>
                <span className={styles.salePrice}>{Price.format( AppConstants.LOCALE, AppConstants.CURRENCY, product.sale.price )}</span>
              </span>
              }
              </div>
            </div>
            <div className='u-offset-1 u-offset-lg-1 u-col-3 u-col-lg-2'>
              <Select
                onChange={this.onProductSizeChange.bind(this, {item, productSizes})}
                withoutFeedback
                placeholder={`Select Size`}
                value={currentSize}
                options={sizeOptions}
              />
            </div>
            <div className='u-offset-2 u-offset-lg-1 u-col-1'>
              <RemoveButton
                className={`${styles.removeIcon} u-float-r`}
                onClick={this.onRemoveButtonClick.bind(this, item)}
              />
            </div>
          </div>
        </div>
      )
    })
    let bagMessage
    if (this.state.items.length === 0) bagMessage = <div className={``}><p className={`u-mrg-b--40 t-title-1`}>Your bag is empty</p><MainBtn text='continue shopping' url='/' /></div>
    else bagMessage = `Shopping Bag`
    return (
      <Layout>
        <SeoHead
          title='Bag'
          path={this.props.location.pathname}
        />
        <Breadcrumbs items={this.breadcrumbsData}/>
        <div className='u-height--only-lg--60'/>
        <div className='u-height--lg--60'/>
        <div className={`${styles.header} u-row u-pad-b--40`}>
          <div className={`${styles.title} u-col-12 u-offset-lg-1 u-col-lg-8 u-pad-x--lg t-title-2`}>{bagMessage}</div>
          { this.state.items.length >= 1 &&
          <div className={`${styles.headerBtnWrapper} u-text-center u-col-12 u-col-lg-2`}>
            <MainBtn className={`${styles.headerBtn} u-float-r`} text='continue shopping' url='/' />
          </div>
          }
        </div>
        <div className='u-height--only-lg--30' />
        <div className='u-row'>
          <div className='u-col-12 u-col-lg-7 u-offset-lg-1'>
            { products }
          </div>
          { this.state.items.length >= 1 &&
            <div className={`u-col-12 u-col-lg-3 ${styles.sidePanel}`}>
              <div className={`${styles.sidePanelInside} t-bg-mediumgray`}>
                <h3 className={`${styles.sidePanelInsideTitle} t-color-black t-paragraph-1 u-text-center`}>Order Summary</h3>
                <div className='u-height--20' />
                <div className='u-separator t-bg-gray'></div>
              </div>
              <div className={`u-pad-x t-bg-mediumgray`}>
                {!this.state.promoIsApplied &&
                <PromoCodeAccordion
                  ref={this.promoForm}
                  isLoading={this.state.promoIsLoading}
                  onFormSubmit={this.onFormSubmit}
                />
                }
                {this.state.promoIsApplied && <div className='u-height--20' />}
                <div className={`u-height--only-lg--10`} />
                <SummaryInformation promo={this.state.promo} lockSale={lockSale} />
                <div className='u-height--lg--20'/>
                <div className='u-height--only-lg--20'/>
                <BackgroundBtn
                  className='u-pad-x u-fit-w'
                  text='Checkout'
                  highlighted
                  onClick={this.onCheckoutClick}
                />
                <div className='u-height--lg--30'/>
                <div className='u-height--only-lg--30'/>
                {/* <h3 className={`t-title-1 u-uppercase u-text-center`}>OR</h3>
                <div className='u-height--lg--10'/>
                <div className='u-height--only-lg--10'/>
                <PaypalButton lockSale={lockSale} totalAmount={AppStore.bagItemsTotalAmount()} />
                <div className='u-height--lg--20'/>
                <div className='u-height--only-lg--20'/> */}
              </div>
            </div>
          }
        </div>
        <div className='u-height--120' />
      </Layout>
    )
  }
}

export default BagPage

export const pageQuery = graphql`
  query {
    dataJson {
      PRIVATE_SALE
    }
  }
`
