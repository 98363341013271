import React, { Component } from 'react'
import AppConstants from 'AppConstants'
import AppStore from 'AppStore'
import AppActions from 'AppActions'
import { getBagItems } from 'Components/Utils/helpers'
import { PayPalButton } from 'react-paypal-button-v2'

class PPB extends Component {
  constructor(props) {
    super(props)
  }
  componentDidMount() {
  }
  onClick = () => {
    setTimeout(AppActions.startPaymentProcess, 0)
  }
  onSuccess = (payment) => {
    setTimeout(AppActions.finishPaymentProcess, 0, 'succeeded', { payment: { transaction_id: `paypal__${payment.id}` } })
    // console.log('Payment is successful!', payment)
  }
  onCancel = (data) => {
    setTimeout(AppActions.finishPaymentProcess, 0, 'cancelled')
    // console.log('Payment is cancelled!', data)
  }
  onError = (err) => {
    setTimeout(AppActions.finishPaymentProcess, 0, 'catch_error')
    // console.log('Error!', err)
  }
  render() {
    const env = process.env.GATSBY_ENV === 'production' ? 'production' : 'sandbox'
    const bagItems = getBagItems()
    const ids = bagItems.map(item => { return item.product.id }).join(';')
    const variationIds = bagItems.map(item => { return item.size.variation_id }).join(';')
    const promoCodes = AppStore.promoCodes.map(item => { return item.code }).join(';')
    const amount = this.props.lockSale ? this.props.totalAmount.totalWithPromos : this.props.totalAmount.totalWithSaleAndPromos
    return (
      <PayPalButton
        options={{
          clientId: this.props.client,
          currency: this.props.currency,
          disableFunding: 'credit,card,bancontact'
        }}
        locale={this.props.locale}
        env={env}
        onClick={this.onClick}
        onSuccess={this.onSuccess}
        onCancel={this.onCancel}
        onError={this.onError}
        createOrder={(data, actions) => actions.order.create({
          purchase_units: [
            {
              reference_id: `order_of_[id]${ids}[/id][variation_id]${variationIds}[/variation_id][env]${env}[/env][discount_codes]${promoCodes}[/discount_codes]`,
              amount: {
                value: 0
              }
            }
          ]
        })}
        style={{
          size: 'large',
          color: 'blue',
          shape: 'pill',
          height: 50
        }}
      />
    )
  }
}

PPB.defaultProps = {
  client: process.env.GATSBY_PAYPAL_CLIENT,
  commit: true,
  currency: AppConstants.CURRENCY,
  locale: 'en_US'
}

export default PPB
