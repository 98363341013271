import React from 'react'
import PropTypes from 'prop-types'

import styles from './index.module.scss'

const IconButton = (props) => {
  let {
    className,
    negative,
    onClick,
    svgId,
  } = props
  return (
    <button className={`${className} simple-text-icon-button u-user-select--none`} onClick={onClick}>
      <svg className={`u-fit ${negative ? 't-fill-color-white t-stroke-color-white' : '' }`}>
        <use xlinkHref={`#${svgId}`} />
      </svg>
    </button>
  )
}

IconButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  svgId: PropTypes.string.isRequired,
}

export default IconButton
