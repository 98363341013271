import React from 'react'
import styles from './index.module.scss'

const PlusMinusIcon = (props) => {
  const { size } = props
  const { isMinus } = props
  const minusClass = isMinus ? styles.minus : ''
  return (
    <div className={`${props.className} ${styles.parent} ${minusClass}`} style={{ width: size, height: size }}>
      <div className={styles.lineA}></div>
      <div className={styles.lineB}></div>
    </div>
  )
}

PlusMinusIcon.defaultProps = {
  size: 20,
  isMinus: false
}

export default PlusMinusIcon
