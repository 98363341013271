import React from 'react'
import PropTypes from 'prop-types'
import IconButton from 'Components/UI/IconButton'
import styles from './index.module.scss'

const RemoveButton = (props) => {
  let {
    className,
    onClick,
  } = props
  return (
    <div className={`${styles.parent} ${className}`}>
      <IconButton 
        className={`${styles.iconButton} u-fit`}
        onClick={onClick}
        svgId={`remove-icon`}
      />
    </div>
  )
}

RemoveButton.propTypes = {
  onClick: PropTypes.func.isRequired
}

export default RemoveButton
