import React, { Component } from 'react'
import PlusMinusIcon from 'Components/UI/PlusMinusIcon'
import styles from './index.module.scss'

class Accordion extends Component {
  constructor(props) {
    super(props)
    this.panel = React.createRef()
    this.state = {
      open: this.props.open ? this.props.open : false
    }
    this.cb = this.props.onClick ? this.props.onClick : () => {}
  }
  componentDidMount() {
    this.setupPanel()
  }
  setupPanel = () => {
    if (this.state.open) {
      this.panel.current.style.maxHeight = `${this.panel.current.scrollHeight}px`
      this.panel.current.style.opacity = 1
    } else {
      this.panel.current.style.maxHeight = null
      this.panel.current.style.opacity = 0
    }
    setTimeout(this.cb, 400)
  }
  onClick = () => {
    this.setState({
      open: !this.state.open
    }, this.setupPanel)
  }
  render() {
    return (
      <div className={`${this.props.className} ${this.state.open ? styles.isActive : ''}`}>
        <button className={`${styles.mainButton} ${this.props.borderColor} t-paragraph-2 u-fit-w u-pad-y--small t-color-black`} onClick={this.onClick}>
          <div className={`u-row ${styles.titleContainer}`}>
            { this.props.icon && <img className={styles.mainIcon} src={this.props.icon}/> }
            <div className={`${this.props.textClassName}`}>{this.props.title}</div>
          </div>
          <PlusMinusIcon size={14} isMinus={this.state.open} className={styles.plusMinus} />
        </button>
        <div className={`${styles.panel} ${this.props.panelClassName}`} ref={this.panel} dangerouslySetInnerHTML={this.props.dangerouslySetInnerHTML}>
          {this.props.children}
        </div>
      </div>
    )
  }
}

Accordion.defaultProps = {
  panelClassName: `t-color-gray t-paragraph-1`,
  borderColor: `t-border-color-gray`
}

export default Accordion
